import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import Image from "../ImageQuerys/LargeFeaturesImages";
import { viewports } from "../../style-vars";

const { smOrSmaller, mdOrSmaller } = viewports;
// import BusinessTypes from '../BusinessTypes/BusinessTypes';

const { Title } = Typography;

const VetsSpecial = (props) => {
  const { sectionData, style } = props;
  const { title, subtext, imgs } = sectionData;
  return (
    <section className="large-features" style={style}>
      {title && (
        <Title className="text-center large-features__main-title">
          {title}
          <span className="blue_dot">.</span>{" "}
        </Title>
      )}
      <p className="text-center vets-p">{subtext}</p>
      <div className="custom-container">
        {imgs.map((img, idx) => (
          <div className={`item-${idx + 1}`}>
            <Image className="vets-img" imageName={img} />
          </div>
        ))}
      </div>
      <style jsx>{`
        .hello {
          position: relative;
        }
        .vets-p {
          color: #748093;
          line-height: 160%;
          font-size: 16px;
          padding: 0 20%;
        }
        .custom-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 600px;
          margin-top: 80px;
        }
        .item-1 {
          flex: 0 22%;
          height: 100px;
          margin-bottom: 2%; /* (100-32*3)/2 */
        }
        .item-2 {
          flex: 0 50%;
          height: 100px;
          margin-bottom: 2%; /* (100-32*3)/2 */
          margin-top: 32px;
        }
        .item-3 {
          flex: 0 22%;
          height: 100px;
          margin-bottom: 2%; /* (100-32*3)/2 */
        }
        .vets-img {
          width: 100%;
        }
        @media screen and (max-width: 1024px) {
          .custom-container {
            margin-bottom: 400px;
          }
        }
        @media ${mdOrSmaller} {
          .vets-p {
            padding: 0 10%;
          }
          .custom-container {
            margin-bottom: 300px;
          }
        }
        @media ${smOrSmaller} {
          .vets-p {
            padding: 0 16px;
          }
          .custom-container {
            margin-top: 40px;
            margin-bottom: 50px;
            justify-content: space-evenly;
          }
          .item-1,
          .item-2,
          .item-3 {
            flex: 0 100%;
            padding-bottom: 100%;
            max-height: 200px;
            overflow: hidden;
            border-radius: 32px;
          }
        }
      `}</style>
    </section>
  );
};

VetsSpecial.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  customItem: PropTypes.any,
};
VetsSpecial.defaultProps = {
  sectionData: "",
  style: {},
  customItem: "",
};

export default VetsSpecial;
